import {
  Datagrid,
  List,
  Filter,
  TextField,
  TextInput,
  EditButton,
  Edit,
  SimpleForm,
} from "react-admin";
import { Grid } from "@mui/material";
const ClubInfoFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="id" />
  </Filter>
);

export const ClubInfoList = (props: any) => (
  <List {...props} filters={<ClubInfoFilter />}>
    <Datagrid>
      <TextField source="id" label="Section" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const AddressEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <Grid container spacing={2} maxWidth={"md"}>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="address1" label="Address One" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="address2" label="Address Two" />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextInput fullWidth source="city" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput fullWidth source="us_state" label="State" />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput fullWidth source="postal_code" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="lat" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="lon" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="phone" type="phone" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="email" type="email" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="website" type="url" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);

const ClubInfoAdmin = {
  list: ClubInfoList,
  edit: AddressEdit,
};
export default ClubInfoAdmin;
