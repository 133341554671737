import { useRecordContext, useUpdate } from "react-admin";

const CheckInPass = () => {
  const record = useRecordContext();
  const midnightTonight = new Date();
  midnightTonight.setHours(24, 0, 0, 0);
  const diff = {
    dateExpired: midnightTonight,
    dateFirstUsed: new Date(),
    status: "active",
  };
  const [update, { isLoading, error }] = useUpdate();
  const handleClick = () => {
    update("passes", { id: record.id, data: diff, previousData: record });
  };
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <button disabled={isLoading} onClick={handleClick}>
      Check In
    </button>
  );
};

export default CheckInPass;
