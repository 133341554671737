import { Container, Grid } from "@mui/material";
import { RecentCheckins } from "../components/RecentCheckins";
import { RecentOrders } from "../components/RecentOrders";
import DailySummary from "../components/DailySummary";

export const Dashboard = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <DailySummary />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={0}>
            <Grid item xs={6}>
              <RecentCheckins />
            </Grid>
            <Grid item xs={6}>
              <RecentOrders />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
