import { AppBar, Layout, TitlePortal } from "react-admin";
import Typography from "@mui/material/Typography";

const ClubAppBar = (props: any) => (
  <AppBar {...props} elevation={0} sx={{ borderBottom: "1px solid grey" }}>
    <Typography variant="h6" color="inherit" sx={{ mr: 2 }}>
      The Cola St. Louis
    </Typography>
    <TitlePortal />
  </AppBar>
);

export const ClubLayout = (props: any) => (
  <Layout {...props} appBar={ClubAppBar} />
);
