import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DateTimeInput,
  required,
  ReferenceInput,
  SelectInput,
  ReferenceField,
} from "react-admin";

const CheckinFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="member" alwaysOn />
  </Filter>
);

export const CheckinList = (props: any) => (
  <List {...props} filters={<CheckinFilter />}>
    <Datagrid>
      <ReferenceField source="memberId" reference="members">
        <TextField source="displayName" />
      </ReferenceField>
      <ReferenceField source="passId" reference="passes">
        <TextField source="type" />
      </ReferenceField>
      <TextField source="dateUsed" />
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const CheckinShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="dateUsed" />
      <ReferenceField source="memberId" reference="members">
        <TextField source="displayName" />
      </ReferenceField>
      <TextField source="passId" />
    </SimpleShowLayout>
  </Show>
);

export const CheckinCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>
      <TextInput source="passId" />
      <DateTimeInput source="dateUsed" />
    </SimpleForm>
  </Create>
);

export const CheckinEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>
      <DateTimeInput source="dateUsed" />
      <TextInput source="passId" />
      <TextInput disabled source="createdate" />
      <TextInput disabled source="lastupdate" />
    </SimpleForm>
  </Edit>
);

const CheckinsAdmin = {
  list: CheckinList,
  show: CheckinShow,
  create: CheckinCreate,
  edit: CheckinEdit,
};
export default CheckinsAdmin;
