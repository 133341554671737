import {
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import CheckInPass from "../components/CheckInPass";

const PassFilter = (props: any) => {
  return (
    <Filter {...props}>
      <SelectInput
        source="status"
        choices={[
          { id: "active", name: "Active" },
          { id: "expired", name: "Expired" },
          { id: "pending", name: "Pending" },
          { id: "shared", name: "Shared" },
        ]}
        alwaysOn
      />
      <SelectInput
        source="type"
        choices={[
          { id: "day", name: "Day" },
          { id: "week", name: "Week" },
          { id: "month", name: "Month" },
          { id: "year", name: "Year" },
          { id: "ticket", name: "Ticket" },
        ]}
        alwaysOn
      />
    </Filter>
  );
};

export const PassList = (props: any) => (
  <List
    {...props}
    filters={<PassFilter />}
    sort={{ field: "createdate", order: "DESC" }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <DateField source="createdate" label="Created On" />
      <TextField source="status" />
      <ReferenceField source="memberId" reference="members">
        <TextField source="displayName" />
      </ReferenceField>
      <ReferenceField source="memberId" reference="members">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="type" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const PassShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="memberId" reference="members">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="orderId" reference="orders">
        <TextField source="squarePaymentId" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="status" />
      <DateField source="dateFirstUsed" />
      <DateField source="dateExpired" />
    </SimpleShowLayout>
  </Show>
);

export const PassEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <CheckInPass />
      <TextInput source="memberId" />
      <TextInput source="type" />
      <TextInput source="status" />
      <DateTimeInput source="dateFirstUsed" />
      <DateTimeInput source="dateExpired" />
    </SimpleForm>
  </Edit>
);

const PassAdmin = {
  list: PassList,
  show: PassShow,
  edit: PassEdit,
};
export default PassAdmin;
