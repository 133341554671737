import { useRef, useEffect } from "react";

type EventHandlerProps = {
  detail: {
    fileName: string;
    data: number;
  };
};

const EventMonitor = () => {
  const eventMonitorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const uploadWillStartEventHandler = ({
      detail: { fileName },
    }: EventHandlerProps) => {
      console.log(`upload '${fileName}' will start`);
      // use a react "toast" module (such as react-toastify) to display notifications
    };

    const uploadRunningEventHandler = ({
      detail: { fileName },
    }: EventHandlerProps) => {
      console.log(`upload '${fileName}' running`);
      // use a react "toast" module (such as react-toastify) to display notifications
    };

    const uploadProgressEventHandler = ({
      detail: { fileName, data },
    }: EventHandlerProps) => {
      console.log(`upload '${fileName}' progress ${data}%`);
      // use a react "toast" module (such as react-toastify) to display notifications
    };

    const uploadCompleteEventHandler = ({
      detail: { fileName },
    }: EventHandlerProps) => {
      console.log(`upload '${fileName}' complete`);
      // use a react "toast" module (such as react-toastify) to display notifications
    };

    const fileReadyEventHandler = ({
      detail: { fileName },
    }: EventHandlerProps) => {
      console.log(`file '${fileName}' ready`);
      // use a react "toast" module (such as react-toastify) to display notifications
    };

    const eventMonitor = eventMonitorRef.current;
    if (!eventMonitor) return; // never too cautious

    // @ts-ignore
    eventMonitor.addEventListener(
      "FILE_UPLOAD_WILL_START",
      uploadWillStartEventHandler
    );
    // @ts-ignore
    eventMonitor.addEventListener(
      "FILE_UPLOAD_RUNNING",
      uploadRunningEventHandler
    );
    // @ts-ignore
    eventMonitor.addEventListener(
      "FILE_UPLOAD_PROGRESS",
      uploadProgressEventHandler
    );
    // @ts-ignore
    // eventMonitor.addEventListener('FILE_UPLOAD_PAUSED', ___);
    // @ts-ignore
    // eventMonitor.addEventListener('FILE_UPLOAD_CANCELD', ___);
    // @ts-ignore
    eventMonitor.addEventListener(
      "FILE_UPLOAD_COMPLETE",
      uploadCompleteEventHandler
    );
    // @ts-ignore
    eventMonitor.addEventListener("FILE_SAVED", fileReadyEventHandler);

    // unsubscribe on unmount
    return () => {
      if (!eventMonitor) return; // never too cautious

      // @ts-ignore
      eventMonitor.removeEventListener(
        "FILE_UPLOAD_WILL_START",
        uploadWillStartEventHandler
      );
      // @ts-ignore
      eventMonitor.removeEventListener(
        "FILE_UPLOAD_RUNNING",
        uploadRunningEventHandler
      );
      // @ts-ignore
      eventMonitor.removeEventListener(
        "FILE_UPLOAD_PROGRESS",
        uploadProgressEventHandler
      );
      // @ts-ignore
      // eventMonitor.removeEventListener('FILE_UPLOAD_PAUSED', ___);
      // @ts-ignore
      // eventMonitor.removeEventListener('FILE_UPLOAD_CANCELD', ___);
      // @ts-ignore
      eventMonitor.removeEventListener(
        "FILE_UPLOAD_COMPLETE",
        uploadCompleteEventHandler
      );
      // @ts-ignore
      eventMonitor.removeEventListener("FILE_SAVED", fileReadyEventHandler);
    };
  }, []);

  return <div id="eventMonitor" ref={eventMonitorRef} />;
};

export default EventMonitor;
