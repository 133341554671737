import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  BooleanInput,
  NumberField,
  BooleanField,
  NumberInput,
} from "react-admin";

const DiscountFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="code" alwaysOn />
  </Filter>
);

export const DiscountList = (props: any) => (
  <List {...props} filters={<DiscountFilter />}>
    <Datagrid>
      <TextField source="code" label="Discount Code" />
      <NumberField source="amountOff" />
      <NumberField source="percentOff" />
      <BooleanField source="active" />
      <BooleanField source="onetime" />
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const DiscountShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="code" label="Discount Code" />
      <NumberField source="amountOff" />
      <NumberField source="percentOff" />
      <BooleanField source="active" />
      <BooleanField source="onetime" />
    </SimpleShowLayout>
  </Show>
);

export const DiscountCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="code" fullWidth />
      <NumberInput source="amountOff" />
      <NumberInput source="percentOff" />
      <BooleanInput source="active" />
      <BooleanInput source="onetime" />
    </SimpleForm>
  </Create>
);

export const DiscountEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="code" fullWidth />
      <NumberInput source="amountOff" />
      <NumberInput source="percentOff" />
      <BooleanInput source="active" />
      <BooleanInput source="onetime" />
    </SimpleForm>
  </Edit>
);

const DiscountsAdmin = {
  list: DiscountList,
  show: DiscountShow,
  create: DiscountCreate,
  edit: DiscountEdit,
};
export default DiscountsAdmin;
