import {
  ArrayField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  downloadCSV,
  required,
} from "react-admin";

import { centsToCurrency } from "../utils";
import jsonExport from "jsonexport/dist";

const exporter = (
  records: any[],
  fetchRelatedRecords: (arg0: any, arg1: string, arg2: string) => Promise<any>
) => {
  fetchRelatedRecords(records, "member_id", "members").then((members: any) => {
    const data = records.map((record: any) => ({
      ...record,
      member_name: members.displayName,
      member_email: members.email,
    }));
    return jsonExport(
      data,
      {
        headers: ["id", "memberId", "member_name", "createdate", "amount"],
      },
      (err: any, csv: string) => {
        downloadCSV(csv, "orders");
      }
    );
  });
};

const OrderFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="member" alwaysOn />
    <TextInput label="Date" source="createdate" />
  </Filter>
);

export const OrderList = (props: any) => (
  <List
    {...props}
    exporter={exporter}
    filters={<OrderFilter />}
    perPage={25}
    sort={{ field: "createdate", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <DateField source="createdate" label="Order Date" />
      <ReferenceField source="memberId" reference="members">
        <TextField source="displayName" /> - <TextField source="email" />
      </ReferenceField>
      <FunctionField
        source="amount"
        render={(record: { amount: number }) => centsToCurrency(record.amount)}
      />
      <EditButton label="" />
    </Datagrid>
  </List>
);

export const OrderShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="createdate" label="Order Date" />
      <ReferenceField source="memberId" reference="members">
        <TextField source="displayName" /> - <TextField source="email" />
      </ReferenceField>
      <TextField source="status" />
      <FunctionField
        source="amount"
        render={(record: { amount: number }) => centsToCurrency(record.amount)}
      />
      <TextField source="discount.code" />
      <ArrayField source="lineItems">
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="sku" />
          <TextField source="eventName" />
          <TextField source="eventId" />
          <TextField source="amount" />
          <TextField source="quantity" />
        </Datagrid>
      </ArrayField>
      <FunctionField
        source="shippingAddress"
        render={(record: { shippingAddress: any }) => {
          if (!record.shippingAddress.address) {
            return <p>No shipping address</p>;
          }
          return (
            <div>
              <p>{record.shippingAddress?.name}</p>
              <p>{record.shippingAddress?.address}</p>
              <p>
                {record.shippingAddress?.city}, {record.shippingAddress?.state}{" "}
                {record.shippingAddress?.zip} {record.shippingAddress?.country}
              </p>
            </div>
          );
        }}
      />
    </SimpleShowLayout>
  </Show>
);

export const OrderCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>

      <DateTimeInput
        source="createdate"
        defaultValue={new Date().toUTCString()}
      />
    </SimpleForm>
  </Create>
);

export const OrderEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>
      <TextInput disabled source="createdate" />
      <TextInput disabled source="lastupdate" />
    </SimpleForm>
  </Edit>
);

const OrdersAdmin = {
  list: OrderList,
  show: OrderShow,
  edit: OrderEdit,
};
export default OrdersAdmin;
