import { ReferenceField, TextField } from "react-admin";

export const MemberLink = ({ record }: any) => (
  <>
    <ReferenceField
      record={record}
      source="memberId"
      reference="members"
      link="show"
    >
      <TextField source="displayName" variant="body2" />
    </ReferenceField>
  </>
);
