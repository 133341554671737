import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EmailField,
  Filter,
  ImageField,
  InfiniteList,
  ReferenceManyCount,
  ReferenceManyField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import CheckInPass from "../components/CheckInPass";
import MuiTextField from "@mui/material/TextField";
import { useState } from "react";

const CreatePassAside = () => {
  const [passType, setPassType] = useState("Day"); // ['day', 'week', 'month', 'year'
  const [amount, setAmount] = useState("15.00"); // [15, 50, 150, 500]
  const record = useRecordContext();

  const createPass = async () => {
    const base_url =
      "https://us-central1-manageyourclub-973b2.cloudfunctions.net";

    const sourceId = { token: "CASH" };
    const memberId = record.id;
    const lineItems = [
      {
        amount,
        name: `${passType} Pass`,
        description: `${passType} Pass`,
        quantity: 1,
        type: passType.toLowerCase(),
        productType: "pass",
      },
    ];
    const clubId = "cola-stl";
    try {
      const response = await fetch(`${base_url}/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          memberId,
          clubId,
          lineItems,
          discount: null,
          total: parseFloat(amount) * 100,
          currency: "USD",
          sourceId,
        }),
      });
      const data = await response.json();
      console.log("checkout response", data);
    } catch (error) {
      console.log("checkout error", error);
    }
  };

  const handlePassTypeChange = (event: SelectChangeEvent) => {
    setPassType(event.target.value);
  };

  if (!record) return null;

  return (
    <Paper sx={{ width: 200, ml: 1 }} elevation={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Select
          label="Pass Type"
          name="passType"
          variant="outlined"
          sx={{ width: "100%", marginBottom: "1em" }}
          value={passType}
          onChange={handlePassTypeChange}
        >
          <MenuItem value="Day">Day Pass</MenuItem>
          <MenuItem value="Week">Week Pass</MenuItem>
          <MenuItem value="Month">Month Pass</MenuItem>
          <MenuItem value="Year">Year Pass</MenuItem>
        </Select>
        <MuiTextField
          label="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={createPass}>
          Create Pass
        </Button>
      </Box>
    </Paper>
  );
};

const MemberFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search by Email" source="email" alwaysOn />
    <TextInput label="Search by Name" source="displayName" alwaysOn />
    <BooleanInput label="Host" source="isHost" />
  </Filter>
);

export const MemberList = (props: any) => (
  <InfiniteList
    {...props}
    perPage={50}
    filters={<MemberFilter />}
    sort={{
      field: "createdate",
      order: "DESC",
    }}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="displayName" label="Name" sx={{ fontWeight: 600 }} />
      <EmailField source="email" />
      <DateField source="createdate" label="Member Since" />
      <ReferenceManyCount label="Passes" reference="passes" target="memberId" />
      <ReferenceManyCount
        label="Check Ins"
        reference="checkins"
        target="memberId"
      />
      <DateField source="lastLogin" label="Last Login" />
      <EditButton label="" />
    </Datagrid>
  </InfiniteList>
);

const passRowSx = (record: { status: string }) => ({
  backgroundColor: record.status === "active" ? "green" : "inherit",
});

export const MemberShow = (props: any) => (
  <Show aside={<CreatePassAside />} {...props}>
    <SimpleShowLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <ImageField source="photoURL" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box>
            <TextField source="id" />
          </Box>
          <Box>
            <TextField source="displayName" label="Name" variant="h5" />
          </Box>
          <Box>
            <EmailField source="email" />
          </Box>
          <Box>
            <Typography>
              Host: <BooleanField label="Host" source="isHost" />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <DateField
        source="createdate"
        label="Member Since"
        defaultValue={new Date().toUTCString()}
      />
      <DateField source="lastLogin" label="Last Login" />
      <ReferenceManyField label="Passes" reference="passes" target="memberId">
        <Datagrid rowSx={passRowSx} bulkActionButtons={false}>
          <TextField source="type" />
          <TextField source="status" />
          <TextField source="sku" />
          <CheckInPass />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export const MemberEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="displayName" label="Name" />
      <TextInput source="email" type="email" />
      <BooleanInput source="isHost" />
      <Divider />
      <TextInput
        disabled
        source="createdate"
        defaultValue={new Date().toUTCString()}
      />
      <TextInput
        disabled
        source="lastupdate"
        defaultValue={new Date().toUTCString()}
      />
    </SimpleForm>
  </Edit>
);

const MembersAdmin = {
  list: MemberList,
  show: MemberShow,
  edit: MemberEdit,
};
export default MembersAdmin;
