import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ImageField,
  BooleanInput,
  BooleanField,
  NumberInput,
  FunctionField,
  ShowButton,
} from "react-admin";

import { centsToCurrency } from "../utils";

const ProductFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search by category" source="category" alwaysOn />
    <TextInput label="Search by name" source="name" />
  </Filter>
);

export const ProductList = (props: any) => (
  <List {...props} filters={<ProductFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="category" />
      <TextField source="description" />
      <FunctionField
        label="Amount"
        source="amount"
        render={(record: any) => centsToCurrency(record.amount)}
      />
      <ImageField source="image" />
      <BooleanField source="active" />
      <ShowButton label="" />
    </Datagrid>
  </List>
);

export const ProductShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="category" />
      <TextField source="description" />
      <FunctionField
        label="Amount"
        source="amount"
        render={(record: any) => centsToCurrency(record.amount)}
      />
      <ImageField source="image" />
      <BooleanField source="active" />
    </SimpleShowLayout>
  </Show>
);

export const ProductCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput source="category" fullWidth />
      <TextInput source="description" fullWidth />
      <NumberInput source="amount" label={"Amount (in cents)"} />
      <TextInput source="image" fullWidth />
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);

export const ProductEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" fullWidth />
      <TextInput source="category" fullWidth />
      <TextInput source="description" fullWidth />
      <NumberInput source="amount" label="Amount (in cents)" />
      <TextInput source="image" fullWidth />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);

const ProductsAdmin = {
  list: ProductList,
  show: ProductShow,
  create: ProductCreate,
  edit: ProductEdit,
};
export default ProductsAdmin;
