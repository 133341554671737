import { useGetList, SimpleList } from "react-admin";
import { Card, CardHeader } from "@mui/material";
import { MemberLink } from "./MemberLink";
export const RecentCheckins = () => {
  const { data, total, isLoading, error } = useGetList("checkins", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "createdate", order: "DESC" },
  });
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <Card>
      <CardHeader title="Recent Checkins" />
      <SimpleList
        data={data}
        total={total}
        isLoading={isLoading}
        primaryText={(record) => `${record.createdate.toLocaleDateString()}`}
        tertiaryText={(record) => `${record.createdate.toLocaleTimeString()}`}
        secondaryText={(record) => <MemberLink record={record} />}
        resource="checkins"
      />
    </Card>
  );
};
