import {
  ArrayField,
  ArrayInput,
  Button,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  FilterButton,
  FilterForm,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  Pagination,
  RecordContextProvider,
  ReferenceField,
  ReferenceManyCount,
  ReferenceManyField,
  RichTextField,
  Show,
  SimpleForm,
  SimpleFormIterator,
  TabbedShowLayout,
  TextField,
  TextInput,
  useGetList,
  useRecordContext,
  useRedirect,
} from "react-admin";
import DateRangeIcon from "@mui/icons-material/DateRange";

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import { RichTextInput } from "ra-input-rich-text";

const EventFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const EventList = (props: any) => {
  return (
    <List {...props} filters={<EventFilter />}>
      <Datagrid rowClick="show">
        <ImageField source="image.src" label="Image" />
        <TextField source="name" label="Event Name" />
        <NextEventInstanceField />
        <ReferenceManyCount
          label="Sold"
          reference="passes"
          target="eventId"
          filter={{ status: "pending" }}
        />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};

const NextEventInstanceField = ({ record }: { record?: any }) => {
  const { data: instances, isLoading } = useGetList("eventInstances", {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "startDate", order: "ASC" },
    filter: { eventId: record?.id },
  });

  if (isLoading || !instances || instances.length === 0) {
    return <span>No upcoming instances</span>;
  }

  const nextInstance = instances[0];
  return <DateField record={nextInstance} source="startDate" showTime />;
};

const EventInstancesFilter = [
  <TextInput label="Search" source="q" alwaysOn />,
  <DateInput label="Start Date" source="startDate" />,
  <DateInput label="End Date" source="endDate" />,
];

const EventInstancesTab = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList("eventInstances", {
    filter: { eventId: record?.id },
    sort: { field: "startDate", order: "ASC" },
    pagination: { page: 1, perPage: 10 },
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <p>ERROR: {error.message}</p>;
  }

  if (!data || data.length === 0) {
    return <p>No event instances found.</p>;
  }

  return (
    <Box>
      <FilterForm filters={EventInstancesFilter} />
      <FilterButton filters={EventInstancesFilter} />
      <Datagrid data={data}>
        <DateField source="startDate" label="Starts At" showTime />
        <DateField source="endDate" label="Ends At" showTime />
        <TextField source="description" label="Description" />
      </Datagrid>
      <Pagination />
    </Box>
  );
};

export const EventShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <ImageField source="image.src" label="Image" />
        <TextField source="summary" />
        <RichTextField source="description" label="Event Description" />
        <ArrayField source="tickets" label="Tickets">
          <Datagrid>
            <TextField source="id" label="Ticket ID" />
            <TextField source="name" label="Ticket Name" />
            <TextField source="description" label="Ticket Description" />
            <TextField source="amount" label="Price in cents" />
          </Datagrid>
        </ArrayField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="instances" icon={<DateRangeIcon />}>
        <RecordContextProvider>
          <EventInstancesTab />
        </RecordContextProvider>{" "}
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Sales">
        <ReferenceManyField
          label="Sold"
          reference="passes"
          target="eventId"
          filter={{ status: "pending" }}
        >
          <Datagrid>
            <DateField source="createdate" />
            <TextField source="name" />
            <ReferenceField source="memberId" reference="members">
              <TextField source="displayName" />
              <TextField source="email" />
            </ReferenceField>
            <TextField source="amount" />
            <TextField source="status" />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);

export const EventCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="id"
        label="Event Slug"
        required
        helperText="unique-name-of-event"
      />
      <TextInput source="name" label="Event Name" fullWidth />
      <ImageInput source="image" label="Image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="summary" fullWidth />
      <RichTextInput source="description" label="Event Description" fullWidth />
      <Divider sx={{ width: "100%" }} />
      <TextInput source="location.name" label="Location Name" />
      <TextInput source="location.address" label="Location Address" />
      <Box
        sx={{
          p: 2,
          mt: 2,
          mb: 2,
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Tickets
        </Typography>
        <ArrayInput source="tickets" label="">
          <SimpleFormIterator>
            <TextInput source="id" label="Ticket ID" />
            <TextInput source="name" label="Ticket Name" />
            <TextInput source="description" label="Ticket Description" />
            <NumberInput source="amount" label="Price in cents" />
            <TextInput source="productType" label="" defaultValue={"ticket"} />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      <DateInput source="createdate" defaultValue={new Date()} disabled />
      <DateInput source="lastupdate" defaultValue={new Date()} disabled />
    </SimpleForm>
  </Create>
);

const EventInstancesField = () => {
  const record = useRecordContext();
  const { data, isLoading } = useGetList("eventInstances", {
    filter: { eventId: record.id },
    sort: { field: "startDate", order: "ASC" },
    pagination: { page: 1, perPage: 10 },
  });

  if (isLoading) return null;

  return (
    <Box>
      <Datagrid data={data} isLoading={isLoading}>
        <DateField source="startDate" label="Starts At" showTime />
        <DateField source="endDate" label="Ends At" showTime />
        <TextField source="description" label="Description" />
        <EditButton />
      </Datagrid>
      <Pagination />
      <CreateEventInstanceButton />
    </Box>
  );
};

const CreateEventInstanceButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();

  const handleClick = () => {
    redirect("create", "eventInstances", record.id);
  };

  return (
    <Button
      onClick={handleClick}
      label="Add Instance"
      variant="contained"
    ></Button>
  );
};

export const EventEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm sanitizeEmptyValues>
        <TextInput disabled source="id" label="Event Slug" />
        <TextInput source="name" label="Event Name" fullWidth />
        <ImageInput source="image" label="Image" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="summary" fullWidth />
        <RichTextInput
          source="description"
          label="Event Description"
          defaultValue=""
          fullWidth
        />
        <TextInput source="location.name" label="Location Name" fullWidth />
        <TextInput
          source="location.address"
          label="Location Address"
          fullWidth
        />
        <Box
          sx={{
            p: 2,
            mt: 2,
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Instances
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <EventInstancesField />
        </Box>
        <Box
          sx={{
            p: 2,
            mt: 2,
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Tickets
          </Typography>
          <ArrayInput source="tickets" label="">
            <SimpleFormIterator inline>
              <TextInput source="id" label="Ticket ID" />
              <TextInput source="name" label="Ticket Name" />
              <TextInput source="description" label="Ticket Description" />
              <NumberInput source="amount" label="Price" />
              <TextInput source="productType" defaultValue="ticket" disabled />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Divider sx={{ width: "100%" }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInput disabled source="createdate" />
          </Grid>
          <Grid item xs={6}>
            <DateInput disabled source="lastupdate" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const EventsAdmin = {
  list: EventList,
  show: EventShow,
  create: EventCreate,
  edit: EventEdit,
};
export default EventsAdmin;
