import { useGetList, SimpleList } from "react-admin";
import { CardHeader, Card, Typography } from "@mui/material";
import { MemberLink } from "./MemberLink";
import { centsToCurrency } from "../utils";

export const RecentOrders = () => {
  const { data, total, isLoading, error } = useGetList("orders", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "createdate", order: "DESC" },
  });

  if (error) {
    return <Typography color={"warning"}>{error.message}</Typography>;
  }

  return (
    <Card>
      <CardHeader title="Recent Orders" />
      <SimpleList
        resource="orders"
        linkType="show"
        data={data}
        total={total}
        isLoading={isLoading}
        primaryText={(record) => `${record.createdate.toLocaleDateString()}`}
        tertiaryText={(record) => centsToCurrency(record.amount)}
        secondaryText={(record) => <MemberLink record={record} />}
      />
    </Card>
  );
};
