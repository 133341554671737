import {
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

const EventInstanceFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput label="Event" source="eventId" reference="events">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const EventInstanceList = (props: any) => (
  <List {...props} filters={<EventInstanceFilter />}>
    <Datagrid rowClick="show">
      <ReferenceField source="eventId" reference="events">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startDate" showTime />
      <DateField source="endDate" showTime />
      <TextField source="description" />
      <EditButton />
    </Datagrid>
  </List>
);

export const EventInstanceShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="eventId" reference="events">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="startDate" showTime />
      <DateField source="endDate" showTime />
      <TextField source="description" />
    </SimpleShowLayout>
  </Show>
);

export const EventInstanceCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="eventId" reference="events">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <TextInput source="description" multiline />
    </SimpleForm>
  </Create>
);

export const EventInstanceEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="eventId" reference="events">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <TextInput source="description" multiline />
    </SimpleForm>
  </Edit>
);

const EventInstancesAdmin = {
  list: EventInstanceList,
  show: EventInstanceShow,
  create: EventInstanceCreate,
  edit: EventInstanceEdit,
};

export default EventInstancesAdmin;
