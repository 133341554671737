import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  BooleanInput,
  NumberField,
  BooleanField,
  NumberInput,
  FunctionField,
  SelectInput,
} from "react-admin";
import { centsToCurrency } from "../utils";

export const passTypes = [
  { id: "day", name: "Day" },
  { id: "week", name: "Week" },
  { id: "month", name: "Month" },
  { id: "year", name: "Year" },
];

const PassTemplateFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput source="type" choices={passTypes} />
  </Filter>
);

export const PassTemplateList = (props: any) => (
  <List {...props} filters={<PassTemplateFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description" />
      <NumberField source="duration" />
      <TextField source="type" />
      <FunctionField
        source="amount"
        render={(record: any) => centsToCurrency(record.amount)}
      />
      <BooleanField source="onSale" />
    </Datagrid>
  </List>
);

export const PassTemplateShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="type" />
      <TextField source="description" />
      <NumberField source="duration" />
      <FunctionField
        source="amount"
        render={(record: any) => centsToCurrency(record.amount)}
      />
      <BooleanField source="onSale" />
    </SimpleShowLayout>
  </Show>
);

export const PassTemplateCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={passTypes} />
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth />
      <NumberInput source="duration" />
      <NumberInput source="amount" label="amount (in cents)" />
      <TextInput source="type" />
      <BooleanInput source="onSale" />
    </SimpleForm>
  </Create>
);

export const PassTemplateEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <SelectInput source="type" choices={passTypes} />
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth />
      <NumberInput source="duration" />
      <NumberInput source="amount" label="amount (in cents)" />
      <BooleanInput source="onSale" />
    </SimpleForm>
  </Edit>
);

const PassTemplatesAdmin = {
  list: PassTemplateList,
  show: PassTemplateShow,
  create: PassTemplateCreate,
  edit: PassTemplateEdit,
};
export default PassTemplatesAdmin;
